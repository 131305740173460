import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonUnderline } from "../buttons"
import { BsArrowRight } from "react-icons/bs"

const device = {
  md: "48em",
}

const Flex = styled.div`
  background: var(--clr-light-secondary);
  display: flex;

  @media screen and (max-width: ${device.md}) {
    flex-direction: column;
  }

  & > * {
    width: 100%;
  }
`

const ProjectImg = styled.div``
const ProjectText = styled.div`
  height: 100%;
  padding: 2em;
  align-self: center;
  display: flex;
  flex-direction: column;
`

export default function CommercialGallery() {
  return (
    <div>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/commercial/single-phase-commercial-load-center.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">
            single phase commercial load center
          </p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/commercial/events-container-panel.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">events container panel</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/commercial/emt-conduit-installation-and-wire-pulling-full-production-workshop.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">
            EMT conduit installation and wire pulling for full production
            workshop
          </p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/commercial/storage-container-panel-work.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">storage container panel work</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/commercial/queen-west-storefront.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">queen St. west storefront</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/commercial/dundas-west-restaurant.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">dundas St. west restaurant</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
    </div>
  )
}
