import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerActions from "../../components/banners/bannerActions"
import { Section, Container } from "../../components/layoutComponents"
import SpecificProject from "../../components/gallery/specificProject"
import Banner from "../../images/banner.png"
import Title from "../../components/title"
import CommercialGallery from "../../components/gallery/commercialGallery"
import FeaturedTestimonial from "../../components/testimonials/featuredTestimonial"

export default function CommercialProjects() {
  return (
    <Layout>
      <SEO title="Commercial Electrical Projects" />
      <BannerActions img={Banner} />
      <Section>
        <Container className="spacing">
          <Title title="commercial electrical project gallery" />
          <CommercialGallery />
          {/* <SpecificProject
            img={Project6}
            altText="commercial electrical project ontario"
            description="single phase commercial load center"
          />
          <SpecificProject
            img={Project1}
            altText="commercial electrical project ontario"
            description="events container panel"
          />
          <SpecificProject
            img={Project2}
            altText="commercial electrical project ontario"
            description="EMT conduit installation and wire pulling for full production workshop"
          />
          <SpecificProject
            img={Project3}
            altText="commercial electrical project ontario"
            description="storage container panel work"
          />
          <SpecificProject
            img={Project4}
            altText="commercial electrical project ontario"
            description="queen St. west storefront"
          />
          <SpecificProject
            img={Project5}
            altText="commercial electrical project ontario"
            description="dundas St. west restaurant"
          /> */}
        </Container>
      </Section>
      <FeaturedTestimonial
        testimonial="The team at Royco Electric always deliver exemplary service. Prompt and professional, they have extensive knowledge in all aspects of their trade that spans many properties types – commercial, residential, mixed-use, and more. Their quality of work is of the highest standard and they’re willing to be flexible to fit your needs. 100% would recommend them to anyone looking for a reliable electrician."
        company="BJL Properties Inc."
        name="krystal bedard"
      />
    </Layout>
  )
}
